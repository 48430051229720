@import "variables/main";

@media (min-width: 768px) and (max-width: 1440px) {
    .landing-page {
        .content {
            .title {
                font-size: $font-xl !important;
            }

            .sub-title {
                font-size: $font-md !important;
            }
        }
    }

    .home-page {
        .main-section {
            .title {
                font-size: $font-xl !important;
            }
        }

        .manage-team-section,
        .journey-section,
        .certificate-section {
            .information {
                .header {
                    .title {
                        font-size: 40px !important;
                    }

                    .number {
                        font-size: 70px !important;
                        top: -90px !important;
                    }
                }

                .desc {
                    @apply text-xl;
                }
            }
        }

        .manage-team-section {
            .group-manager {
                left: 39% !important;
            }
        }

        .journey-section {
            height: 900px !important;

            .image {
                width: 70% !important;
                margin: auto;
                top: 0 !important;
            }
        }

        .feedback-section,
        .instructors-section {
            > .header {
                .title {
                    font-size: 40px !important;
                }

                .description {
                    font-size: 20px !important;
                }
            }

            .instructor-carousel {
                .instructor {
                    height: 370px !important;
                }
            }
        }
    }

    .workshops {
        .workshop-filter {
            .root-node {
                font-size: 20px !important;
                font-weight: 700 !important;
            }

            .mat-tree-node {
                font-size: 14px !important;
            }
        }

        .workshop {
            .price {
                font-size: 18px;
            }
        }
    }
}

@media (max-width: 1440px) {
    .home-page {
        .certificate-section {
            .image-cont {
                .image {
                    width: 470px !important;
                    height: 426px !important;
                }
            }
        }

        .feedback-section {
            .feedback-carousel {
                .header {
                    .img img {
                        width: 64px !important;
                        height: 64px !important;
                    }
                }

                .description {
                    margin-left: 80px !important;
                }
            }
        }

        .instructors-section {
            .instructor-carousel {
                .instructor {
                    .description {
                        .name {
                            font-size: 22px !important;
                        }
                    }
                }
            }
        }
    }

    .instructor-stepper-form {
        file-upload {
            padding: 20px !important;
        }

        .upload-input {
            width: 100%;
            text-align: center;
            left: 45% !important;
        }
    }
}

@media (max-width: 1439px) {
    .mat-stepper-horizontal,
    .mat-stepper-vertical {
        .mat-horizontal-content-container {
            padding: 3rem 0 !important;
        }
    }

    .home-page {
        .track-progress {

            img {
                width: 330px !important;
                height: 330px !important;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1439px) {
    .mat-stepper-horizontal,
    .mat-stepper-vertical {
        .mat-horizontal-stepper-header-container {
            padding: 0 !important;

            .mat-horizontal-stepper-header {
                padding: 0 !important;
            }
        }
    }
}

@media (max-width: 1280px) {
	.booking-details{
		.summary-box {
			.buttons {
				top: calc(100% - 25px) !important;
			}
		}
		.details {
			.name, .desc {
				width: 100% !important;
			}
		}
	}
    .workshop-details {
        .image-cont {
            img {
                height: 300px;
            }
        }

        .summary-box {
            position: relative !important;
            top: 0 !important;
            height: 300px !important;

            .item {
                .icon {
                    height: 34px !important;
                    width: 34px !important;
                    padding: 0.3rem !important;
                }

                .title {
                    font-size: 10px !important;
                }

                .content {
                    font-size: 12px !important;
                }
            }

            button.mat-raised-button {
                top: 25px !important;
                font-size: 12px !important;
                height: 32px !important;
            }
        }

        .details {
            .name {
                font-size: 28px !important;
            }

            .desc {
                font-size: 16px !important;
            }
        }
    }

    .instructor-sign-up {
        .terms-and-conditions {
            font-size: 14px !important;
        }
    }
}

@media (min-width: 1280px) {
    .book-workshop-form {
        .inline-calendar-card {
            &.instructor-selection {
                margin: unset !important;
            }
        }
    }
}

@media (max-width: 768px) {
    .home-page {
        .feedback-section {
            .feedback-carousel {
                .feedback {
                    padding: 1rem !important;

                    .header {
                        .emp-info {
                            .emp-name {
                                font-size: 16px !important;
                            }
                        }

                        .img {
                            margin-top: 1.25rem;

                            img {
                                width: 40px !important;
                                height: 40px !important;
                            }
                        }
                    }

                    .description {
                        margin-left: 57px !important;
                    }
                }
            }
        }

        .instructors-section {
            .instructor-carousel {
                .instructor {
                    .description {
                        .name {
                            font-size: 16px !important;
                        }
                    }
                }
            }
        }
    }

    .forget-password {
        width: 445px !important;
        height: 455px !important;

        .icon-cont {
            width: 62px !important;
            height: 62px !important;

            .icon {
                height: 60px !important;
                background: url("#{$path-to-icons}/key-sm.svg") !important;
            }
        }

        form {
            .mat-flat-button {
                height: 42px !important;
            }
        }
    }

    .instructor-sign-up {
        .instructor-benefits {
            .item {
                .icon-cont {
                    width: 30px !important;
                    height: 30px !important;

                    .icon {
                        width: 19px !important;
                        height: 19px !important;
                    }
                }

                &.inspire {
                    .icon-cont {
                        .icon {
                            background: url("#{$path-to-icons}/inspire-sm.svg") !important;
                        }
                    }
                }

                &.join-family {
                    .icon-cont {
                        .icon {
                            background: url("#{$path-to-icons}/join-family-sm.svg") !important;
                        }
                    }
                }

                &.be-rewarded {
                    .icon-cont {
                        .icon {
                            background: url("#{$path-to-icons}/be-rewarded-sm.svg") !important;
                        }
                    }
                }
            }
        }

        .mat-flat-button {
            height: 42px !important;
        }
    }

    .send-email-success-message {
        width: 445px !important;
        height: 285px !important;

        .icon-cont {
            width: 56px !important;
            height: 56px !important;

            .icon {
                width: 39px !important;
                height: 37px !important;
                background: url("#{$path-to-icons}/send-mail-sm.svg") !important;
            }
        }

        .desc {
            font-size: 16px !important;
        }
    }

    .send-email-error-message {
        width: 445px !important;
        height: 210px !important;

        .icon-cont {
            width: 56px !important;
            height: 56px !important;

            .icon {
                width: 37px !important;
                height: 39px !important;
                background: url("#{$path-to-icons}/error-sm.svg") !important;
            }
        }
    }

    .email-verified {
        width: 445px !important;
        height: 260px !important;

        .icon-cont {
            width: 56px !important;
            height: 56px !important;

            .icon {
                width: 40px !important;
                height: 40px !important;
                background: url("#{$path-to-icons}/email-letter-more-sm.svg") !important;
            }
        }

        .mat-flat-button {
            height: 42px !important;
        }
    }
}

@media (max-width: 766px) {
    .landing-page {
        .content {
            .title {
                font-size: 40px !important;
                max-width: 32rem !important;
            }

            .sub-title {
                font-size: 20px !important;
            }
        }

        .start-journey {
            .text {
                font-size: 14px !important;
            }
        }
    }

    .home-page {
        .main-section {
            background-position: right bottom !important;
            background-attachment: unset !important;

            .title {
                font-size: 40px !important;
                max-width: 32rem !important;
            }

            .sub-title {
                font-size: 20px !important;
            }
        }

        .category-section {
            .header {
                font-size: 28px !important;
            }
        }

        .manage-team-section,
        .journey-section,
        .certificate-section {
            .information {
                .header {
                    .title {
                        font-size: 32px !important;
                        bottom: 2rem !important;
                        padding-left: 15px !important;
                    }

                    .number {
                        font-size: 48px !important;
                        top: -48px !important;
                    }
                }

                .desc {
                    font-size: 16px !important;
                    bottom: 1rem !important;
                    padding-left: 15px !important;
                }
            }
        }

        .manage-team-section {
            .group-manager {
                left: 39% !important;
            }
        }

        .journey-section {
            height: 750px !important;

            .image {
                position: relative !important;
                top: 0 !important;
                height: 400px !important;
            }
        }

        .certificate-section {
            .image {
                height: 400px !important;
            }
        }

        .feedback-section,
        .instructors-section {
            > .header {
                .title {
                    font-size: 32px !important;
                }

                .description {
                    font-size: 16px !important;
                }
            }

            .instructor-carousel {
                .instructor {
                    height: 300px !important;
                }

                .description {
                    .name {
                        font-size: 16px !important;
                    }

                    .position {
                        font-size: 10px !important;
                    }
                }
            }
        }
    }

    .workshop-details {
        .summary-box {
            margin-bottom: 2rem;
            height: 220px !important;

            .item {
                .icon {
                    height: 34px !important;
                    width: 34px !important;
                    padding: 0.3rem !important;
                    margin-right: 0.5rem !important;
                }

                .title {
                    font-size: 10px !important;
                }

                .content {
                    font-size: 13px !important;
                }

                &:not(:last-child) {
                    margin-bottom: 1rem;
                }
            }

            button.mat-raised-button {
                top: 15px !important;
                font-size: 16px !important;
                width: 250px !important;
                margin: 0 !important;
                height: 40px !important;
            }
        }

        .details {
            .name {
                font-size: 24px !important;
            }

            .desc {
                font-size: 14px !important;
            }

            .tabs {
                .tab-details {
                    &.instructor {
                        .cont {
                            .instructor {
                                padding: 1rem 1rem 1.5rem;

                                .image {
                                    img {
                                        height: 40px !important;
                                        width: 40px !important;
                                        margin: 0 !important;
                                    }
                                }

                                .instructor-info {
                                    padding-top: 0 !important;

                                    .instructor-name {
                                        font-size: 16px !important;
                                    }

                                    .instructor-title {
                                        font-size: 14px !important;
                                    }

                                    .instructor-desc {
                                        font-size: 14px !important;
                                    }
                                }
                            }
                        }

                        .load-more-btn button {
                            background-color: $purple-060;
                            width: 200px;
                        }
                    }
                }
            }
        }
    }

    .sign-in {
        .logo {
            width: 89px !important;
        }
    }

    .instructor-sign-up {
        .terms-and-conditions {
            font-size: 12px !important;
        }
    }

    .forget-password {
        width: 305px !important;
        height: 400px !important;

        .icon-cont {
            width: 40px !important;
            height: 40px !important;

            .icon {
                height: 40px !important;
                background: url("#{$path-to-icons}/key-xsm.svg") !important;
            }
        }

        .desc {
            font-size: 12px !important;
        }

        form {
            .mat-flat-button {
                height: 40px !important;
            }
        }
    }

    .send-email-success-message {
        width: 305px !important;
        height: 225px !important;

        .icon-cont {
            width: 40px !important;
            height: 40px !important;

            .icon {
                width: 25px !important;
                height: 25px !important;
                background: url("#{$path-to-icons}/send-mail-xsm.svg") !important;
            }
        }

        .desc {
            font-size: 12px !important;
        }
    }

    .send-email-error-message,
    .sign-up-error-message {
        width: 305px !important;
        height: 145px !important;

        .icon-cont {
            width: 48px !important;
            height: 48px !important;

            .icon {
                width: 30px !important;
                height: 30px !important;
                background: url("#{$path-to-icons}/error-xsm.svg") !important;
            }
        }
    }

    .email-verified {
        width: 305px !important;
        height: 235px !important;

        .icon-cont {
            width: 48px !important;
            height: 48px !important;

            .icon {
                width: 33px !important;
                height: 33px !important;
                background: url("#{$path-to-icons}/email-letter-more-xs.svg") !important;
            }
        }

        .mat-flat-button {
            height: 40px !important;
        }
    }

    .confirmation-dialog {
        width: 300px !important;
        height: 200px !important;

        .mat-flat-button {
            height: 32px !important;
        }
    }

    .mat-horizontal-stepper-header {
        padding: 0 !important;
        height: 85px !important;
        flex-direction: column;

        .mat-step-icon {
            margin-right: 0 !important;
            margin-left: 8px !important;
            margin-bottom: 8px;
        }
    }

    .mat-horizontal-stepper-header-container {
        padding: 20px 0 !important;
    }

    .mat-step-header {
        width: 100%;
    }

    .mat-step-label {
        white-space: pre-line !important;
        text-align: center !important;
    }

    .book-workshop-form {
        .mat-horizontal-content-container {
            padding-bottom: 1rem !important;
        }

        .inline-calendar-card {
            width: 95% !important;
        }

        .mat-calendar-content {
            tbody {
                td {
                    .mat-calendar-body-cell-content {
                        left: 25% !important;
                        width: 50% !important;
                        height: 60%;
                    }
                }
            }
        }
    }

    .track-progress {
        height: 740px !important;
    }
}

@media (max-width: 375px) {
    .landing-page {
        .content {
            .title {
                font-size: 32px !important;
                margin-bottom: 1rem;
            }

            .sub-title {
                font-size: 18px !important;
            }
        }

        .start-journey {
            .text {
                font-size: 12px !important;
            }
        }
    }

    .home-page {
        .main-section {
            background-position: right bottom !important;
            background-attachment: unset !important;

            .title {
                font-size: 32px !important;
                margin-bottom: 1rem;
            }

            .sub-title {
                font-size: 16px !important;
            }
        }

        .category-section {
            .header {
                font-size: 24px !important;
            }
        }

        .manage-team-section,
        .journey-section,
        .certificate-section {
            .information {
                .header {
                    .title {
                        font-size: 32px !important;
                        bottom: 2rem !important;
                        padding-left: 15px !important;
                    }

                    .number {
                        font-size: 48px !important;
                        top: -48px !important;
                    }
                }

                .desc {
                    font-size: 16px !important;
                    bottom: 1rem !important;
                    padding-left: 15px !important;
                }
            }
        }

        .manage-team-section {
            .image {
                .team-member-container {
                    .team-member {
                        width: 32px !important;
                        height: 32px !important;
                    }
                }
            }

            .group-manager {
                bottom: 37% !important;
                left: 39% !important;

                img {
                    width: 65px !important;
                    height: 65px !important;
                }

                .text {
                    font-size: 12px !important;
                }
            }
        }

        .journey-section {
            height: 650px !important;

            .image {
                position: relative !important;
                top: -100px !important;
                height: 400px !important;
            }
        }

        .certificate-section {
            .image-cont {
                .image {
                    width: 330px !important;
                    height: 230px !important;
                }
            }
        }

        .feedback-section,
        .instructors-section {
            > .header {
                .title {
                    font-size: 32px !important;
                }

                .description {
                    font-size: 16px !important;
                }
            }

            .instructor-carousel {
                .instructor {
                    height: 300px !important;
                }

                .description {
                    .name {
                        font-size: 16px !important;
                    }

                    .position {
                        font-size: 10px !important;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1100px) {
    .instructor-profile-header {
        flex-direction: row-reverse;
    }

    .take-full-mid-screen {
        width: 100% !important;
        padding-right: 90px;
    }

    .right-section {
        width: 210px !important;
    }

    .left-section {
        width: calc(100% - 210px) !important;
    }

    .instructor-profile-image {
        width: 75px;
        height: 75px;
    }

    .right-section .instructor-profile-image-container {
        top: 40px !important;
        left: 40px !important;
    }

    .instructor-skills-container {
        display: none !important;
    }

    .instructor-skills-container-block {
        display: block !important;
    }

	.instructor-profile-image {
		width: 140.5px !important;
		height: 150.6px !important;
	}

	.workshop-card {
		width: 20.625;
	}

	app-instructor-profile {
		.text-left {
			width: 370px !important;
			justify-content: flex-start !important;
		}
		.instructor-name-container {
			justify-content: space-between !important;
			margin-left: 0px !important;
		}
	}

	.booking-details {
		.payment-section, .empty-state {
			width: 100% !important;
			max-width: unset;
		}
		.name {
			font-size: 24px !important;
		}
		.desc {
			font-weight: 500;
			font-size: 16px !important;
		}
		.document-container {
			width: 100%;
			max-width: unset !important
		}
	}
}

@media (min-width: 1px) and (max-width: 661px) {
        .home-page {
            .certificate-section {
                .image-cont {
                    .image {
                        width: 420px !important;
                    }
                }
            }
        }
	.booking-details {
		.instructor-profession {
			width: 250px;
		}
	}
	.empty-state {
		width: 100% !important;
		max-width: unset;
		padding: unset !important;
	}

    .instructor-profile-header {
        justify-content: center !important;
        align-items: center;
    }

    .instructor-profile-header {
        flex-direction: column-reverse;
    }

    .take-full-mid-screen {
        width: 100% !important;
        min-width: unset !important;
    }

    .right-section {
        width: 210px !important;
    }

    .instructor-profile-image {
        width: 75px;
        height: 75px;
    }

    .right-section .instructor-profile-image-container {
        top: 40px !important;
        left: 40px !important;
    }

    .left-section {
        width: 100% !important;
    }

    .instructor-image-section {
        width: 150px !important;
    }

    .instructor-view-image {
        width: 50px !important;
        height: 50px !important;
    }

    .header .instructor-image-section .instructor-view-image-container {
        top: 50px !important;
        left: 50px !important;
    }

    app-view-instructor-info .instructor .header .left-section {
        width: calc(100% - 150px) !important;
        .instructor-name {
            font-size: 24px;
            font-weight: bold;
        }
    }

	.instructor-profile-image {
		width: 140.5px !important;
		height: 150.6px !important;
	}
}
