@import "~styles/variables/main";

body {

    [class^='mat-']:not(.mat-icon) {
        font-family: Fieldwork, sans-serif !important;
    }

    .mat-tree {
        background-color: $purple-800;
    }

    .mat-card {
        padding: 0 0 16px;
        background: $purple-600;

        .mat-card-image {
            margin: 0;

        }

        .mat-card-content {
            padding: 1rem;
            font-size: 18px;
            font-weight: 600;
        }

        & > .mat-card-actions:last-child {
            margin: 0;
        }

        .chips {
            font-size: $font-xxs;

            .chip {
                background-color: #524467;
                border-radius: 3rem;
                padding: 0 0.75rem;
                margin: 0 0.25rem;
            }
        }
    }

    .mat-chip.mat-standard-chip {
        background-color: $purple-060;
    }
}
