/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import 'media-query';
@import "variables/main";

.adyen-checkout__spinner__wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center
}

.adyen-checkout__spinner__wrapper--inline {
    display: inline-block;
    height: auto;
    margin-right: 8px
}

[dir=rtl] .adyen-checkout__spinner__wrapper--inline {
    margin-left: 8px;
    margin-right: 0
}

.adyen-checkout__spinner {
    -webkit-animation: rotateSpinner 1.5s linear infinite;
    animation: rotateSpinner 1.5s linear infinite;
    border: 3px solid #06f;
    border-radius: 50%;
    border-top-color: transparent;
    height: 43px;
    width: 43px
}

.adyen-checkout__spinner--large {
    height: 43px;
    width: 43px
}

.adyen-checkout__spinner--small {
    border-width: 2px;
    height: 16px;
    width: 16px
}

.adyen-checkout__spinner--medium {
    height: 28px;
    width: 28px
}

@-webkit-keyframes rotateSpinner {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes rotateSpinner {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.adyen-checkout__button {
    background: #00112c;
    border: 0;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-size: 1em;
    font-weight: 500;
    height: 48px;
    margin: 0;
    padding: 15px;
    text-decoration: none;
    transition: background .3s ease-out, box-shadow .3s ease-out;
    width: 100%
}

.adyen-checkout__button:focus {
    box-shadow: 0 0 0 2px #99c2ff;
    outline: 0
}

.adyen-checkout__button:hover {
    background: #1c3045;
    box-shadow: 0 0, 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14)
}

.adyen-checkout__button:active {
    background: #3a4a5c
}

.adyen-checkout__button:hover:focus {
    box-shadow: 0 0 0 2px #99c2ff, 0 3px 4px rgba(0, 15, 45, .2)
}

.adyen-checkout__button:disabled, .adyen-checkout__button:disabled:hover {
    box-shadow: none;
    cursor: not-allowed;
    opacity: .4;
    -webkit-user-select: all;
    -moz-user-select: all;
    user-select: all
}

.adyen-checkout__button.adyen-checkout__button--loading {
    background: #687282;
    box-shadow: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.adyen-checkout__button.adyen-checkout__button--pay {
    margin-top: 24px
}

.adyen-checkout__button.adyen-checkout__button--pay:disabled {
    opacity: 1
}

.adyen-checkout__button.adyen-checkout__button--standalone {
    margin-top: 0
}

.adyen-checkout__button.adyen-checkout__button--inline {
    display: block;
    font-size: .81em;
    height: auto;
    padding: 10px 8px;
    width: auto
}

.adyen-checkout__button.adyen-checkout__button--ghost {
    background: none;
    border: 0;
    color: #00112c
}

.adyen-checkout__button.adyen-checkout__button--ghost:hover {
    background: #f7f8f9;
    box-shadow: none
}

.adyen-checkout__button.adyen-checkout__button--ghost:active {
    background: #e6e9eb;
    box-shadow: none
}

.adyen-checkout__button.adyen-checkout__button--secondary {
    background: rgba(0, 102, 255, .1);
    border: 1px solid transparent;
    color: #06f;
    padding: 10px 12px
}

.adyen-checkout__button.adyen-checkout__button--secondary:hover {
    background: rgba(0, 102, 255, .2);
    box-shadow: none
}

.adyen-checkout__button.adyen-checkout__button--secondary:active, .adyen-checkout__button.adyen-checkout__button--secondary:active:hover {
    background: rgba(0, 102, 255, .3);
    box-shadow: none
}

.adyen-checkout__button.adyen-checkout__button--link {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 3px;
    color: #06f;
    font-weight: 400;
    padding: 2px
}

.adyen-checkout__button.adyen-checkout__button--link:hover {
    background: transparent;
    box-shadow: none;
    text-decoration: underline
}

.adyen-checkout__button.adyen-checkout__button--completed, .adyen-checkout__button.adyen-checkout__button--completed:active, .adyen-checkout__button.adyen-checkout__button--completed:active:hover, .adyen-checkout__button.adyen-checkout__button--completed:hover {
    background: #0abf53;
    color: #fff
}

.adyen-checkout__button.adyen-checkout__button--completed .adyen-checkout__button__icon {
    filter: brightness(0) invert(1)
}

.adyen-checkout__button__content {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center
}

.adyen-checkout__button__icon {
    margin-right: 12px
}

[dir=rtl] .adyen-checkout__button__icon {
    margin-left: 12px;
    margin-right: 0
}

.adyen-checkout__button__text {
    display: block;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.adyen-checkout__button .adyen-checkout__spinner {
    border-color: transparent #fff #fff
}

.adyen-checkout__fieldset {
    display: block;
    padding-bottom: 8px;
    width: 100%
}

.adyen-checkout__fieldset:last-of-type {
    padding-bottom: 0
}

.adyen-checkout__fieldset + .adyen-checkout__fieldset {
    margin-top: 16px
}

.adyen-checkout__fieldset__title {
    display: none;
    color: #687282;
    font-size: .68em;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 0;
    padding: 0 0 12px;
    text-transform: uppercase
}

.adyen-checkout__field-group, .adyen-checkout__fieldset__fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%
}

.adyen-checkout__field-group:last-of-type .adyen-checkout__field {
    margin-bottom: 0
}

.adyen-checkout__fieldset--readonly .adyen-checkout__fieldset__fields {
    color: #00112c;
    font-size: .81em;
    line-height: 19px;
    margin: 0
}

.adyen-checkout__field {
    display: block;
    margin-bottom: 16px;
    width: 100%
}

.adyen-checkout__field:last-of-type {
    margin-bottom: 0
}

.adyen-checkout__label {
    display: block
}

.adyen-checkout__helper-text, .adyen-checkout__label__text {
    color: #ffffff;
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 13px;
    padding-bottom: 12px
}

.adyen-checkout__helper-text {
    color: #687282
}

.adyen-checkout__label__text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color .1s ease-out;
    white-space: nowrap
}

.adyen-checkout__error-text {
    align-items: center;
    color: #d10244;
    display: flex;
    font-size: .75em;
    font-weight: 400;
    margin-top: 4px
}

.adyen-checkout__radio_group + .adyen-checkout-input__inline-validation {
    display: none
}

.adyen-checkout__radio_group__input {
    opacity: 0;
    position: absolute
}

.adyen-checkout__radio_group__label {
    color: inherit;
    display: block;
    font-size: .81em;
    font-weight: 400;
    line-height: 16px;
    overflow: visible;
    padding-bottom: 0;
    padding-left: 24px;
    position: relative
}

.adyen-checkout__label--focused .adyen-checkout__radio_group__label {
    color: inherit
}

.adyen-checkout__radio_group__label:before {
    background-color: #fff;
    border: 1px solid #b9c4c9;
    border-radius: 50%;
    content: "";
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    transition: border-color .2s ease-out, box-shadow .2s ease-out;
    width: 16px
}

.adyen-checkout__radio_group__label:hover:before {
    border-color: #99a3ad;
    box-shadow: 0 0 0 2px #d4d9db;
    cursor: pointer
}

.adyen-checkout__radio_group__label:after {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 1px 1px rgba(0, 15, 45, .25);
    content: "";
    display: block;
    height: 6px;
    left: 5px;
    margin: 0 auto;
    position: absolute;
    top: 5px;
    transform: scale(0);
    transition: transform .2s ease-out;
    width: 6px
}

.adyen-checkout__radio_group__label:hover {
    border-color: #06f;
    cursor: pointer
}

.adyen-checkout__radio_group__input:checked + .adyen-checkout__radio_group__label:before, .adyen-checkout__radio_group__label--selected {
    background-color: #06f;
    border: 0;
    transition: all .2s ease-out
}

.adyen-checkout__radio_group__input:checked + .adyen-checkout__radio_group__label:after {
    transform: scale(1)
}

.adyen-checkout__radio_group__input:focus + .adyen-checkout__radio_group__label:before {
    border-color: #06f;
    box-shadow: 0 0 0 2px rgba(0, 102, 255, .4)
}

.adyen-checkout__radio_group__input:checked + .adyen-checkout__radio_group__label:hover:before, .adyen-checkout__radio_group__input:checked:active + .adyen-checkout__radio_group__label:before, .adyen-checkout__radio_group__input:checked:focus + .adyen-checkout__radio_group__label:before {
    box-shadow: 0 0 0 2px rgba(0, 102, 255, .4)
}

.adyen-checkout__radio_group__label.adyen-checkout__radio_group__label--invalid:before {
    border: 1px solid #d10244
}

.adyen-checkout__checkbox {
    display: block
}

.adyen-checkout__checkbox__label {
    color: #00112c;
    cursor: pointer;
    display: inline-block;
    font-size: .81em;
    font-weight: 400;
    line-height: 19px;
    padding-left: 24px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

[dir=rtl] .adyen-checkout__checkbox__label {
    padding-left: 0;
    padding-right: 24px
}

.adyen-checkout__checkbox__input {
    opacity: 0;
    pointer-events: none;
    position: absolute
}

.adyen-checkout__checkbox__input:checked + .adyen-checkout__checkbox__label:before {
    opacity: 1
}

.adyen-checkout__checkbox__input:checked + .adyen-checkout__checkbox__label:after {
    background-color: #06f;
    border: 1px solid #06f
}

.adyen-checkout__checkbox__input:checked:hover + .adyen-checkout__checkbox__label:after {
    border-color: #06f;
    box-shadow: 0 0 0 2px rgba(0, 102, 255, .4)
}

.adyen-checkout__checkbox__input:focus + .adyen-checkout__checkbox__label:after {
    border: 1px solid #06f;
    box-shadow: 0 0 0 2px #99c2ff
}

.adyen-checkout__checkbox__input:hover:not(:focus) + .adyen-checkout__checkbox__label:after {
    border-color: #99a3ad;
    box-shadow: 0 0 0 2px #d4d9db
}

.adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label:before {
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent;
    border-color: transparent #fff #fff transparent;
    border-radius: 0 2px 1px 2px;
    border-style: solid;
    border-width: 1px 2px 2px 1px;
    content: "";
    height: 11px;
    left: 1px;
    opacity: 0;
    position: absolute;
    top: 2px;
    transform: rotate(37deg);
    transform-origin: 100% 100%;
    transition: opacity .2s ease-out;
    width: 6px;
    z-index: 1
}

[dir=rtl] .adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label:before {
    left: auto;
    right: 8px
}

.adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label:after {
    background-color: #fff;
    border: 1px solid #b9c4c9;
    border-radius: 3px;
    content: "";
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    transition: background .15s ease-out, border .05s ease-out, box-shadow .1s ease-out;
    width: 16px;
    z-index: 0
}

[dir=rtl] .adyen-checkout__checkbox__input + .adyen-checkout__checkbox__label:after {
    left: auto;
    right: 0
}

.adyen-checkout__field--consentCheckbox {
    background: #e6e9eb;
    border: 1px solid #e6e9eb;
    border-radius: 6px;
    padding: 14px 14px 13px
}

[dir=rtl] .adyen-checkout__field--consentCheckbox {
    padding: 14px 14px 13px
}

.adyen-checkout__field--consentCheckbox.adyen-checkout__field--error {
    border-color: #d10244
}

.adyen-checkout__field--consentCheckbox .adyen-checkout-input__inline-validation {
    right: -27px;
    top: 10px
}

.Select-module_adyen-checkout__dropdown__2kGp2 {
    position: relative
}

.Select-module_adyen-checkout__dropdown__button__waz0I {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between
}

.Select-module_adyen-checkout__dropdown__button__waz0I:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='8' height='7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.195 6.565a1 1 0 0 0 1.6 0l2.992-3.98a1 1 0 0 0-.8-1.602H1.013a1 1 0 0 0-.8 1.6l2.983 3.982Z' fill='%23687282'/%3E%3C/svg%3E");
    background-position: 50%;
    background-repeat: no-repeat;
    content: "";
    height: 6px;
    position: absolute;
    right: 16px;
    width: 8px
}

[dir=rtl] .Select-module_adyen-checkout__dropdown__button__waz0I:after {
    left: 16px;
    right: auto
}

.Select-module_adyen-checkout__dropdown__button--active__1EqeU:after {
    transform: rotate(180deg)
}

.Select-module_adyen-checkout__filter-input__HMjy5 {
    background: transparent;
    border: 0;
    caret-color: #06f;
    color: #00112c;
    font-family: inherit;
    font-size: 1em;
    height: 100%;
    padding: 0;
    width: 100%
}

.Select-module_adyen-checkout__filter-input__HMjy5::-moz-placeholder {
    color: #b9c4c9;
    font-weight: 200
}

.Select-module_adyen-checkout__filter-input__HMjy5:-ms-input-placeholder {
    color: #b9c4c9;
    font-weight: 200
}

.Select-module_adyen-checkout__filter-input__HMjy5::placeholder {
    color: #b9c4c9;
    font-weight: 200
}

.Select-module_adyen-checkout__filter-input__HMjy5:active, .Select-module_adyen-checkout__filter-input__HMjy5:focus {
    outline: 0
}

.Select-module_adyen-checkout__dropdown__list__2UxAp {
    background: #fff;
    display: none;
    list-style: none;
    margin: 0 0 50px;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: 1
}

.Select-module_adyen-checkout__dropdown__list__2UxAp.Select-module_adyen-checkout__dropdown__list--active__Mlt8t {
    display: block
}

.Select-module_adyen-checkout__dropdown__element__3nIQR {
    align-items: center;
    display: flex;
    justify-content: space-between
}

.adyen-checkout__image {
    opacity: 0;
    transition: opacity .6s ease-out
}

.adyen-checkout__image--loaded {
    opacity: 1
}

.adyen-checkout__dropdown {
    font-size: 1em;
    max-width: 100%;
    width: 100%
}

.adyen-checkout__dropdown__button {
    background: $purple-600;
    border-radius: 6px;
    color: #ffffff;
    font-size: 1em;
    height: 48px;
    line-height: 20px;
    outline: 0;
    padding: 7px 24px 7px 12px;
    text-decoration: none;
    transition: border .2s ease-out, box-shadow .2s ease-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%
}

[dir=rtl] .adyen-checkout__dropdown__button {
    padding: 7px 12px 7px 24px
}

.adyen-checkout__dropdown__button:hover {
    border-color: #99a3ad
}

.adyen-checkout__dropdown__button__icon {
    border-radius: 3px;
    height: 26px;
    margin-left: auto;
    margin-right: 8px;
    max-width: 40px
}

.adyen-checkout__dropdown__button--readonly, .adyen-checkout__dropdown__button--readonly--active, .adyen-checkout__dropdown__button--readonly:focus, .adyen-checkout__dropdown__button--readonly:hover {
    background: #e6e9eb;
    border-color: transparent;
    color: #00112c;
    cursor: not-allowed
}

.adyen-checkout__dropdown__button--readonly:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='8' height='7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.195 6.565a1 1 0 0 0 1.6 0l2.992-3.98a1 1 0 0 0-.8-1.602H1.013a1 1 0 0 0-.8 1.6l2.983 3.982Z' fill='%23B9C4C9'/%3E%3C/svg%3E")
}

.adyen-checkout__dropdown__button--invalid {
    border-color: #d10244
}

.adyen-checkout__dropdown__button--valid {
    border-bottom-color: #0abf53
}

.adyen-checkout__dropdown__button__text {
    overflow: hidden;
    pointer-events: none;
    text-overflow: ellipsis;
    white-space: nowrap
}

.adyen-checkout__dropdown__list {
    border-radius: 6px;
    box-shadow: 0 2px 7px rgba(0, 15, 45, .3);
    max-height: 375px;
    z-index: 2;
    background-color: $purple-600;
}

.adyen-checkout__dropdown__list.adyen-checkout__dropdown__list--active {
    margin-top: 2px
}

.adyen-checkout__dropdown__element {
    border: 1px solid transparent;
    cursor: pointer;
    font-size: .81em;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    line-height: 20px;
    outline: 0;
    padding: 8px;
    transition: background .2s ease-out, border-color .2s ease-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    word-break: break-word
}

.adyen-checkout__dropdown__element:last-child {
    border-bottom: 0
}

.adyen-checkout__dropdown__element:active, .adyen-checkout__dropdown__element:focus, .adyen-checkout__dropdown__element:hover {
    background: rgba(230, 233, 235, .6)
}

.adyen-checkout__dropdown__element.adyen-checkout__dropdown__element--active {
    background: rgba(0, 102, 255, .1)
}

.adyen-checkout__dropdown__element.adyen-checkout__dropdown__element--active:active, .adyen-checkout__dropdown__element.adyen-checkout__dropdown__element--active:focus, .adyen-checkout__dropdown__element.adyen-checkout__dropdown__element--active:hover {
    background: rgba(0, 102, 255, .15)
}

.adyen-checkout__dropdown__element--disabled {
    cursor: not-allowed;
    opacity: .4
}

.adyen-checkout__dropdown__element__icon {
    border-radius: 3px;
    margin-right: 8px;
    max-height: 26px;
    max-width: 40px
}

.adyen-checkout__dropdown__element__flag {
    margin-left: 8px;
    margin-right: 10px;
    max-height: 18px;
    max-width: 27px
}

.adyen-checkout__dropdown + .adyen-checkout-input__inline-validation {
    right: 32px
}

.adyen-checkout__field-wrapper {
    display: flex;
    width: 100%
}

.adyen-checkout__field--20 {
    width: 20%
}

.adyen-checkout__field--30 {
    width: 30%
}

.adyen-checkout__field--40 {
    width: 40%
}

.adyen-checkout__field--50 {
    width: 50%
}

.adyen-checkout__field--60 {
    width: 60%
}

.adyen-checkout__field--70 {
    width: 70%
}

.adyen-checkout__field--80 {
    width: 80%
}

.adyen-checkout__field--col-70 {
    width: calc(70% - 8px)
}

.adyen-checkout__field--col-30 {
    width: calc(30% - 8px)
}

.adyen-checkout__field--col-50 {
    width: calc(50% - 8px)
}

.adyen-checkout__field-wrapper > .adyen-checkout__field:first-child {
    margin-right: 8px
}

[dir=rtl] .adyen-checkout__field-wrapper > .adyen-checkout__field:first-child {
    margin-left: 8px;
    margin-right: 0
}

.adyen-checkout__field-wrapper > .adyen-checkout__field:nth-child(2) {
    margin-left: 8px
}

[dir=rtl] .adyen-checkout__field-wrapper > .adyen-checkout__field:nth-child(2) {
    margin-left: 0;
    margin-right: 8px
}

.adyen-checkout__field-wrapper:last-of-type > .adyen-checkout__field {
    margin-bottom: 0
}

.adyen-checkout__input {
    align-items: center;
    height: 48px;
    border-radius: 8px !important;
    padding: 0.75em 1.25rem !important;
    background-color: $purple-600;
    caret-color:#ffffff;
    color: #ffffff !important;
    display: block;
    font-family: inherit;
    font-size: 1em;
    outline: none;
    position: relative;
    transition: border .2s ease-out, box-shadow .2s ease-out;
    width: 100%
}

.adyen-checkout__input:hover {
    border-color: #99a3ad
}

.adyen-checkout__input:required {
    box-shadow: none
}

.adyen-checkout__input--disabled, .adyen-checkout__input[readonly] {
    background: #e6e9eb;
    border-color: #e6e9eb
}

.adyen-checkout__input--disabled:hover {
    border-color: #e6e9eb
}

.adyen-checkout__input-wrapper {
    display: block;
    position: relative
}

.adyen-checkout__input-wrapper--block {
    display: block
}

.adyen-checkout-input__inline-validation {
    height: 16px;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px
}

[dir=rtl] .adyen-checkout-input__inline-validation {
    left: 14px;
    right: auto
}

[dir=ltr] .adyen-checkout-input__inline-validation {
    left: auto;
    right: 14px
}

.adyen-checkout-input__inline-validation--valid {
    color: #0abf53
}

.adyen-checkout-input__inline-validation--invalid {
    color: #d10244
}

.adyen-checkout__input--invalid {
    border-color: #d10244
}

.adyen-checkout__input--valid {
    border-bottom-color: #0abf53
}

.adyen-checkout__input--error, .adyen-checkout__input--error:hover, .adyen-checkout__input--invalid, .adyen-checkout__input--invalid:hover {
    border-color: #d10244
}

.adyen-checkout__input::-moz-placeholder {
    color: #b9c4c9;
    font-weight: 200
}

.adyen-checkout__input:-ms-input-placeholder {
    color: #b9c4c9;
    font-weight: 200
}

.adyen-checkout__input::placeholder {
    color: #b9c4c9;
    font-weight: 200
}

.adyen-checkout__input--date {
    padding-right: 30px
}

//.adyen-checkout__input--focus, .adyen-checkout__input--focus:hover, .adyen-checkout__input:active, .adyen-checkout__input:active:hover, .adyen-checkout__input:focus, .adyen-checkout__input:focus:hover {
//    border: 1px solid #06f;
//    box-shadow: 0 0 0 2px #99c2ff
//}

.adyen-checkout__input[readonly], .adyen-checkout__input[readonly]:hover {
    background-color: #e6e9eb;
    border-color: transparent;
    color: #687282;
    cursor: default
}

.adyen-checkout__fieldset--personalDetails .adyen-checkout__field--gender .adyen-checkout__radio_group {
    display: flex
}

.adyen-checkout__fieldset--personalDetails .adyen-checkout__radio_group {
    display: flex;
    margin: 8px 0
}

.adyen-checkout__fieldset--personalDetails .adyen-checkout__radio_group__input-wrapper {
    margin-right: 20px
}

.adyen-checkout__fieldset--personalDetails .adyen-checkout__radio_group__input-wrapper:last-child {
    margin: 0
}

.adyen-checkout__open-invoice .adyen-checkout__fieldset--billingAddress {
    padding-bottom: 8px
}

.adyen-checkout__open-invoice .adyen-checkout__fieldset--deliveryAddress {
    margin-top: 24px;
    padding-bottom: 8px
}

.adyen-checkout__open-invoice .adyen-checkout__input--separateDeliveryAddress {
    margin-bottom: 0
}

.adyen-checkout__open-invoice .adyen-checkout__field--consentCheckbox {
    margin-top: 22px
}

.adyen-checkout__input--separateDeliveryAddress + .adyen-checkout__checkbox__label {
    margin-top: 16px
}

.adyen-checkout__amazonpay__button {
    margin: auto
}

.adyen-checkout__amazonpay .adyen-checkout__button--ghost {
    display: block;
    margin: 8px auto 0;
    width: auto
}

@supports (-webkit-appearance:-apple-pay-button) {
    .ApplePayButton-module_apple-pay-button__26P3-, .ApplePayButton-module_apple-pay__1liGp {
        -webkit-appearance: -apple-pay-button
    }
    .ApplePayButton-module_apple-pay-button__26P3- {
        cursor: pointer;
        display: inline-block
    }
    .ApplePayButton-module_apple-pay-button-black__3Ml54 {
        -apple-pay-button-style: black
    }
    .ApplePayButton-module_apple-pay-button-white__1qE8A {
        -apple-pay-button-style: white
    }
    .ApplePayButton-module_apple-pay-button-white-with-line__j9FE5 {
        -apple-pay-button-style: white-outline
    }
    .ApplePayButton-module_apple-pay-button--type-plain__2mnnX {
        -apple-pay-button-type: plain
    }
    .ApplePayButton-module_apple-pay-button--type-buy__eMnIy {
        -apple-pay-button-type: buy
    }
    .ApplePayButton-module_apple-pay-button--type-donate__3zvI8 {
        -apple-pay-button-type: donate
    }
    .ApplePayButton-module_apple-pay-button--type-check-out__ipg0J {
        -apple-pay-button-type: check-out
    }
    .ApplePayButton-module_apple-pay-button--type-book__155Xs {
        -apple-pay-button-type: book
    }
    .ApplePayButton-module_apple-pay-button--type-subscribe__3uPJ5 {
        -apple-pay-button-type: subscribe
    }
    .ApplePayButton-module_apple-pay-button--type-add-money__xmCaj {
        -apple-pay-button-type: add-money
    }
    .ApplePayButton-module_apple-pay-button--type-contribute__RCq2P {
        -apple-pay-button-type: contribute
    }
    .ApplePayButton-module_apple-pay-button--type-order__f5tpZ {
        -apple-pay-button-type: order
    }
    .ApplePayButton-module_apple-pay-button--type-reload__1P53C {
        -apple-pay-button-type: reload
    }
    .ApplePayButton-module_apple-pay-button--type-rent__2J4wk {
        -apple-pay-button-type: rent
    }
    .ApplePayButton-module_apple-pay-button--type-support__3-p0R {
        -apple-pay-button-type: support
    }
    .ApplePayButton-module_apple-pay-button--type-tip__2-gCt {
        -apple-pay-button-type: tip
    }
    .ApplePayButton-module_apple-pay-button--type-top-up__9UKXI {
        -apple-pay-button-type: top-up
    }
}

@supports not (-webkit-appearance:-apple-pay-button) {
    .ApplePayButton-module_apple-pay-button__26P3- {
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 100% 60%;
        border-radius: 5px;
        box-sizing: border-box;
        display: inline-block;
        max-height: 64px;
        min-height: 32px;
        min-width: 200px;
        padding: 0
    }
    .ApplePayButton-module_apple-pay-button-black__3Ml54 {
        background-color: black;
        background-image: -webkit-named-image(apple-pay-logo-white)
    }
    .ApplePayButton-module_apple-pay-button-white-with-line__j9FE5, .ApplePayButton-module_apple-pay-button-white__1qE8A {
        background-color: white;
        background-image: -webkit-named-image(apple-pay-logo-black)
    }
    .ApplePayButton-module_apple-pay-button-white-with-line__j9FE5 {
        border: .5px solid black
    }
}

.adyen-checkout__applepay__button {
    height: 48px;
    width: 240px
}

.adyen-checkout__dropin .adyen-checkout__applepay__button {
    width: 100%
}

.adyen-checkout__issuer-button {
    align-items: center;
    background-color: #fff;
    border: none;
    border-radius: 6px;
    box-shadow: inset 0 0 0 1px #b9c4c9;
    cursor: pointer;
    display: flex;
    flex-basis: 47%;
    flex-grow: 2;
    font-size: .81em;
    height: 40px;
    justify-content: space-between;
    padding: 0 12px;
    transition: background .3s ease-out, box-shadow .3s ease-out
}

.adyen-checkout__issuer-button:active {
    color: black
}

.adyen-checkout__issuer-button:not(.adyen-checkout__issuer-button--selected):focus {
    box-shadow: inset 0 0 0 2px #99A3AD;
    outline: none
}

.adyen-checkout__issuer-button:not(.adyen-checkout__issuer-button--selected):focus-visible {
    box-shadow: inset 0 0 0 2px #99A3AD;
    outline: none
}

.adyen-checkout__issuer-button:not(.adyen-checkout__issuer-button--selected):hover {
    box-shadow: inset 0 0 0 2px #99A3AD;
    outline: none
}

.adyen-checkout__issuer-button--selected {
    background: #fff;
    box-shadow: inset 0 0 0 2px #06f;
    color: #06f;
    font-weight: 500;
    height: 40px;
    transition: none
}

.adyen-checkout__issuer-button-img {
    max-height: 26px
}

.adyen-checkout__issuer-button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 16px
}

.adyen-checkout__field--issuer-list-separator {
    align-items: center;
    color: #687282;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    margin-top: 16px;
    white-space: nowrap
}

.adyen-checkout__field--issuer-list-separator:after, .adyen-checkout__field--issuer-list-separator:before {
    background: #e6e9eb;
    content: "";
    display: block;
    height: 1px;
    width: 100%
}

.adyen-checkout__field--issuer-list-separator:after {
    margin-left: 20px
}

.adyen-checkout__field--issuer-list-separator:before {
    margin-right: 20px
}

.adyen-checkout__field--issuer-list {
    margin-bottom: 0
}

.adyen-checkout__card-input__form {
    transition: opacity .25s ease-out
}

.adyen-checkout__card__cardNumber {
    max-width: 400px
}

.adyen-checkout__card__cardNumber__input {
    padding: 5px 8px
}

.adyen-checkout__card__exp-date__input--oneclick {
    font-weight: 400;
    line-height: 30px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap
}

.adyen-checkout__card__holderName, .adyen-checkout__field--expiryDate, .adyen-checkout__field--storedCard {
    margin-bottom: 0
}

.adyen-checkout__card-input .adyen-checkout__fieldset--billingAddress, .adyen-checkout__card__holderName, .adyen-checkout__card__kcp-authentication, .adyen-checkout__card__socialSecurityNumber, .adyen-checkout__installments, .adyen-checkout__store-details {
    margin-top: 16px
}

.adyen-checkout-error-panel + .adyen-checkout__card__holderName, .adyen-checkout-error-panel--sr-only + .adyen-checkout__card__holderName, .adyen-checkout__card__holderName:first-child {
    margin: 0 0 16px
}

.adyen-checkout__field--cardNumber
.adyen-checkout__input--valid:not(.adyen-checkout__card__cardNumber__input--noBrand) + .adyen-checkout-input__inline-validation--valid, .adyen-checkout__field--cardNumber .adyen-checkout__input--error .adyen-checkout__card__cardNumber__brandIcon {
    display: none
}

.adyen-checkout__field--securityCode.adyen-checkout__field--error .adyen-checkout__card__cvc__hint, .adyen-checkout__field--securityCode.adyen-checkout__field--valid .adyen-checkout__card__cvc__hint {
    opacity: 0
}

@-webkit-keyframes cvcIndicateLocation {
    0% {
        opacity: 1
    }
    to {
        opacity: .3
    }
}

@keyframes cvcIndicateLocation {
    0% {
        opacity: 1
    }
    to {
        opacity: .3
    }
}

.adyen-checkout__label--focused .adyen-checkout__field__cvc--back-hint .adyen-checkout__card__cvc__hint--back .adyen-checkout__card__cvc__hint__location, .adyen-checkout__label--focused .adyen-checkout__field__cvc--front-hint .adyen-checkout__card__cvc__hint--front .adyen-checkout__card__cvc__hint__location {
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: cvcIndicateLocation;
    animation-name: cvcIndicateLocation
}

.adyen-checkout__card__cvc__hint__wrapper {
    align-items: center;
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    display: flex;
    height: 100%;
    margin: 0 10px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateZ(0);
    transform-origin: center;
    transform-style: preserve-3d;
    transition: transform .3s cubic-bezier(.455, .03, .515, .955);
    width: 27px;
    will-change: transform
}

.adyen-checkout__field__cvc--front-hint.adyen-checkout__card__cvc__hint__wrapper {
    transform: rotateY(180deg)
}

.adyen-checkout__card__cvc__hint {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    transition: opacity .1s linear
}

.adyen-checkout__card__cvc__hint--front {
    transform: rotateY(180deg)
}

@media (prefers-reduced-motion: reduce) {
    .adyen-checkout__card__cvc__hint__wrapper {
        transition: none
    }
}

.adyen-checkout__fieldset--revolving-plan .adyen-checkout__fieldset__fields {
    justify-content: left
}

.adyen-checkout__fieldset--revolving-plan .adyen-checkout__radio_group {
    display: flex;
    flex-direction: column
}

.adyen-checkout__fieldset--revolving-plan .adyen-checkout__radio_group__input-wrapper {
    margin-top: 20px
}

.adyen-checkout__fieldset--revolving-plan .adyen-checkout__field--revolving-plan-installments {
    margin-left: 15px;
    position: relative;
    top: 42px;
    width: 30%
}

.LoadingWrapper-module_loading-input__form__1jpVs {
    opacity: 1
}

.LoadingWrapper-module_loading-input__form--loading__3LDWz {
    opacity: 0
}

.LoadingWrapper-module_loading-input__spinner__3eCyK {
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1
}

.LoadingWrapper-module_loading-input__spinner--active__3UDtX {
    display: block
}

.CardInput-module_card-input__wrapper__2tAzu {
    position: relative
}

.CardInput-module_card-input__wrapper__2tAzu *, .CardInput-module_card-input__wrapper__2tAzu :after, .CardInput-module_card-input__wrapper__2tAzu :before {
    box-sizing: border-box
}

.CardInput-module_card-input__icon__2Iaf5 {
    border-radius: 3px;
    height: 18px;
    margin-left: 7px;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 27px
}

.CardInput-module_card-input__form__2Ij_n {
    opacity: 1
}

.CardInput-module_card-input__spinner__1wHzq {
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1
}

.CardInput-module_card-input__spinner--active__1Dzoe {
    display: block
}

.CardInput-module_card-input__form--loading__3zh3Y {
    opacity: 0
}

.CardInput-module_adyen-checkout__input__3Jmld {
    display: block;
    max-height: 100px
}

.CardInput-module_adyen-checkout__card__cvc__input--hidden__1Z1lp, .CardInput-module_adyen-checkout__card__exp-date__input--hidden__3850Y {
    display: none
}

.CardInput-module_adyen-checkout__card__exp-cvc__exp-date__input--hidden__3wxr3 {
    justify-content: flex-end
}

.CardInput-module_revolving-plan-installments__disabled__2yP53 {
    opacity: .4;
    pointer-events: none
}

.adyen-checkout-error-panel {
    margin-bottom: 20px
}

.adyen-checkout-error-panel .adyen-checkout-error-panel__wrapper {
    font-size: .75em
}

.adyen-checkout-error-panel--sr-only {
    height: 1px;
    left: -10000px;
    overflow: hidden;
    position: absolute;
    top: auto;
    width: 1px
}

.adyen-checkout__card__dual-branding__buttons {
    display: flex;
    opacity: .4;
    pointer-events: none
}

.adyen-checkout__card__dual-branding__buttons--active {
    opacity: 1;
    pointer-events: auto
}

.adyen-checkout__card__dual-branding__buttons .adyen-checkout__card__cardNumber__brandIcon {
    cursor: pointer;
    opacity: 1
}

.adyen-checkout__card__dual-branding__buttons .adyen-checkout__card__cardNumber__brandIcon:first-child {
    right: 40px
}

.adyen-checkout__card__dual-branding__buttons .adyen-checkout__card__cardNumber__brandIcon--not-selected {
    opacity: .5
}

.adyen-checkout__card__brands {
    display: flex;
    flex-basis: auto;
    flex-shrink: 1;
    flex-wrap: wrap;
    gap: 4px;
    margin-bottom: 16px;
    margin-top: -8px
}

.adyen-checkout__card__brands img {
    border-radius: 3px;
    height: 16px;
    width: 24px
}

.adyen-checkout__card__brands__brand-wrapper--disabled {
    opacity: .25
}

.adyen-checkout__card__brands__brand-wrapper {
    display: inline-block;
    height: 16px;
    position: relative;
    transition: opacity .2s ease-out;
    width: 24px
}

.adyen-checkout__card__brands__brand-wrapper:after {
    border: 1px solid rgba(0, 27, 43, .17);
    border-radius: 3px;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.adyen-checkout__button-group {
    background: transparent;
    display: flex;
    justify-content: space-between
}

.adyen-checkout__button-group .adyen-checkout__button {
    background: transparent;
    border: 0;
    box-shadow: inset 0 0 0 1px #99a3ad;
    color: #00112c;
    font-size: .81em;
    font-weight: 400;
    height: 40px;
    line-height: 40px;
    margin-right: 8px;
    padding: 0;
    text-align: center
}

.adyen-checkout__button-group .adyen-checkout__button:last-child {
    margin-right: 0
}

.adyen-checkout__button-group .adyen-checkout__button:hover {
    background: transparent;
    box-shadow: inset 0 0 0 2px #99a3ad
}

.adyen-checkout__button-group .adyen-checkout__button:active {
    background: #f7f8f9;
    box-shadow: inset 0 0 0 2px #99a3ad
}

.adyen-checkout__button-group .adyen-checkout__button--disabled, .adyen-checkout__button-group .adyen-checkout__button--disabled:hover {
    cursor: not-allowed;
    opacity: .4;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.adyen-checkout__button-group .adyen-checkout__button--selected, .adyen-checkout__button-group .adyen-checkout__button--selected:active, .adyen-checkout__button-group .adyen-checkout__button--selected:active:hover, .adyen-checkout__button-group .adyen-checkout__button--selected:hover {
    background: #e5efff;
    box-shadow: inset 0 0 0 2px #06f;
    color: #06f;
    font-weight: 500;
    height: 40px;
    transition: none
}

.adyen-checkout__button-group .adyen-checkout__button .adyen-checkout__button-group__input {
    opacity: 0;
    pointer-events: none;
    position: absolute
}

.adyen-checkout__adyen-giving .adyen-checkout__status__icon {
    display: block;
    margin: 56px auto 32px
}

.adyen-checkout__adyen-giving .adyen-checkout__status__text {
    color: #00112c;
    margin-bottom: 56px;
    text-align: center
}

.adyen-checkout__campaign {
    background: #00112c;
    border-radius: 6px;
    height: 227px;
    overflow: hidden;
    position: relative
}

.adyen-checkout__campaign-link:hover .adyen-checkout__campaign-description {
    text-decoration: underline
}

.adyen-checkout__campaign-container {
    height: 100%
}

.adyen-checkout__campaign-logo {
    border: 2px solid rgba(255, 255, 255, .4);
    border-radius: 3px;
    display: block;
    height: 48px;
    margin-bottom: 16px;
    overflow: hidden;
    width: 48px
}

.adyen-checkout__campaign-background-image {
    background-color: #00112c;
    background-position: 50%;
    background-size: cover;
    height: 100%
}

.adyen-checkout__campaign-link .adyen-checkout__campaign-background-image:before {
    background: inherit;
    content: "";
    height: 100%;
    position: absolute;
    transition: transform .6s ease-out;
    width: 100%
}

.adyen-checkout__campaign-link .adyen-checkout__campaign-background-image:hover:before {
    transform: scale(1.1)
}

.adyen-checkout__campaign-link .adyen-checkout__campaign-content {
    pointer-events: none
}

.adyen-checkout__campaign-content {
    bottom: 0;
    padding: 16px;
    position: absolute;
    z-index: 2
}

.adyen-checkout__campaign-description, .adyen-checkout__campaign-title {
    color: #fff;
    font-weight: 400;
    margin: 0
}

.adyen-checkout__campaign-title {
    font-size: 1em;
    margin-bottom: 8px
}

.adyen-checkout__campaign-description {
    font-size: .81em;
    line-height: 19px
}

.adyen-checkout__adyen-giving-actions {
    margin-top: 16px
}

.adyen-checkout__button.adyen-checkout__button--donate {
    margin: 16px auto 8px
}

.adyen-checkout__button.adyen-checkout__button--decline {
    display: block;
    margin: auto;
    width: auto
}

.adyen-checkout__paywithgoogle {
    height: 48px
}

.adyen-checkout__paywithgoogle > div > button, .adyen-checkout__paywithgoogle > div > button.long, .adyen-checkout__paywithgoogle > div > button.short {
    height: 48px;
    transition: background-color .3s ease-out, box-shadow .3s ease-out
}

.adyen-checkout__paywithgoogle > div > button.long:focus, .adyen-checkout__paywithgoogle > div > button.short:focus, .adyen-checkout__paywithgoogle > div > button:focus {
    box-shadow: 0 0 0 2px #99c2ff;
    outline: 0
}

.adyen-checkout__paywithgoogle > div > button.gpay-button {
    padding: 15px 24px 13px
}

.adyen-checkout__econtext-input__field > .adyen-checkout__button--pay:only-child {
    margin-top: 0
}

.adyen-checkout__voucher-result {
    border-radius: 12px;
    box-sizing: border-box;
    position: relative;
    text-align: center
}

.adyen-checkout__voucher-result__bottom, .adyen-checkout__voucher-result__top {
    background: #fff;
    border: 1px solid #d4d9db
}

.adyen-checkout__voucher-result__top {
    border-bottom: 0;
    border-radius: 12px 12px 0 0;
    padding: 40px 0 24px
}

.adyen-checkout__voucher-result__bottom {
    border-radius: 0 0 12px 12px;
    border-top: 0
}

.adyen-checkout__voucher-result__separator {
    align-items: center;
    background: #fff;
    display: flex;
    height: 13px;
    margin: 0 auto;
    position: relative;
    width: calc(100% - 14px)
}

.adyen-checkout__voucher-result__separator:after, .adyen-checkout__voucher-result__separator:before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBjbGlwLXBhdGg9InVybCgjYSkiPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJtMCAwIDUgMiAxLjUgNEg3VjBIMFptMCAxMyA1LTIgMS41LTRIN3Y2SDBaIiBmaWxsPSIjZmZmIi8+PHBhdGggZD0iTTYuNDIzIDYuNUM2LjQyMyAzLjMxMiAzLjc4My43NTYuNS41MThjMy4zODYuMjM2IDYgMi44NTUgNiA1Ljk4MiAwIDMuMTI3LTIuNjE0IDUuNzQ2LTYgNS45ODN2LS4wMDFjMy4yODQtLjIzNyA1LjkyMy0yLjc5NCA1LjkyMy01Ljk4MloiIHN0cm9rZT0iI0Q0RDlEQiIvPjxwYXRoIGZpbGw9IiNENEQ5REIiIGQ9Ik0wIDBoMXYxSDB6TTAgMTJoMXYxSDB6Ii8+PC9nPjxkZWZzPjxjbGlwUGF0aCBpZD0iYSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTAgMGg3djEzSDB6Ii8+PC9jbGlwUGF0aD48L2RlZnM+PC9zdmc+)
}

.adyen-checkout__voucher-result__separator:before {
    left: -7px
}

.adyen-checkout__voucher-result__separator:after, .adyen-checkout__voucher-result__separator:before {
    background-position: 100%;
    background-repeat: no-repeat;
    content: "";
    height: 13px;
    position: absolute;
    top: 0;
    width: 7px
}

.adyen-checkout__voucher-result__separator:after {
    right: -7px;
    transform: rotate(-180deg)
}

.adyen-checkout__voucher-result__separator__inner {
    border-top: 1px solid #e6e9eb;
    width: 100%
}

.adyen-checkout__voucher-result__image {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    width: 100%
}

.adyen-checkout__link--voucher-result-instructions {
    display: inline-block
}

.adyen-checkout__voucher-result__image__wrapper {
    display: block;
    height: 48px;
    margin: 0 24px;
    position: relative
}

.adyen-checkout__voucher-result__image__wrapper:after {
    border: 1px solid rgba(0, 27, 43, .17);
    border-radius: 3px;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.adyen-checkout__voucher-result__image__wrapper:nth-child(2):before {
    border-left: 1px solid #d4d9db;
    content: "";
    height: 64px;
    left: -24.5px;
    position: absolute;
    top: -8px;
    width: 1px
}

.adyen-checkout__voucher-result__image__brand, .adyen-checkout__voucher-result__image__issuer {
    border-radius: 3px;
    height: 48px
}

.adyen-checkout__voucher-result__introduction {
    color: #00112c;
    font-size: .81em;
    line-height: 19px;
    margin: 0 auto;
    max-width: 400px;
    text-align: center
}

.adyen-checkout__voucher-result__amount {
    color: #00112c;
    font-size: 1em;
    font-weight: 700;
    margin: 24px auto 0;
    text-align: center
}

.adyen-checkout__voucher-result__surcharge {
    color: #687282;
    display: block;
    font-size: .81em;
    font-weight: 400;
    line-height: 19px;
    text-align: center
}

.adyen-checkout__voucher-result__code__label {
    display: block;
    font-weight: 400;
    left: 0;
    line-height: 19px;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: -2px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: auto
}

.adyen-checkout__voucher-result__code__label:before {
    content: "";
    position: absolute
}

.adyen-checkout__voucher-result__code__label__text {
    background: #fff;
    color: #00112c;
    font-size: 13px;
    letter-spacing: normal;
    line-height: 1;
    padding: 0 8px
}

.adyen-checkout__voucher-result__code__barcode {
    display: block;
    height: 56px;
    margin: 0 auto 8px;
    max-width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.adyen-checkout__voucher-result__code {
    border-width: 1px 0;
    color: #00112c;
    display: inline-block;
    font-size: 1.5em;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 1.2;
    margin: 0 auto;
    padding: 16px 48px;
    position: relative;
    text-align: center;
    -webkit-user-select: all;
    -moz-user-select: all;
    user-select: all;
    width: 100%;
    word-break: break-word
}

.adyen-checkout__voucher-result__details {
    list-style: none;
    margin: -1px auto 0;
    padding: 0
}

.adyen-checkout__voucher-result__details__item {
    border-top: 1px solid #e6e9eb;
    color: #00112c;
    display: flex;
    font-size: .81em;
    justify-content: space-between;
    padding: 16px 24px;
    word-break: break-word
}

.adyen-checkout__voucher-result__details__item:last-child {
    margin-bottom: 0
}

.adyen-checkout__voucher-result__details__label {
    max-width: 50%;
    text-align: left
}

.adyen-checkout__voucher-result__details__value {
    font-weight: 700;
    max-width: 50%;
    text-align: right
}

.adyen-checkout__voucher-result__actions {
    align-items: center;
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0 auto 32px;
    max-width: 100%;
    min-width: 200px;
    padding: 0;
    width: 300px
}

.adyen-checkout__voucher-result__actions__item {
    margin: 0 4px
}

.adyen-checkout__paypal__buttons {
    position: relative;
    z-index: 0
}

.adyen-checkout__paypal__button {
    display: flex;
    margin-bottom: 16px
}

.adyen-checkout__paypal__button:empty {
    display: none
}

.adyen-checkout__paypal__status--pending {
    margin: 16px 0
}

.adyen-checkout__paypal__status--processing {
    align-items: center;
    display: flex;
    font-size: 13px;
    justify-content: center;
    padding: 24px 0
}

.adyen-checkout__payment-method .adyen-checkout__paypal__status--pending {
    margin: -16px 0 38px
}

.adyen-checkout__payment-method .adyen-checkout__paypal__status--processing {
    padding: 20px 0 65px
}

.adyen-checkout__phone-input {
    direction: ltr
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper {
    width: 100%
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__input {
    height: auto;
    padding: 0
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__input:focus {
    border: 1px solid #06f;
    box-shadow: 0 0 0 2px #99c2ff
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__dropdown__button {
    border: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    height: 35px;
    width: auto
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__dropdown__button:after {
    box-sizing: revert;
    height: 10px;
    left: 40px
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__input--phoneNumber {
    border: 1px solid transparent;
    height: 35px;
    margin-left: 8px;
    padding-left: 15px
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__input-wrapper--phoneInput {
    align-items: center;
    display: flex
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__input-wrapper--phoneInput:focus {
    border: 1px solid #06f;
    box-shadow: 0 0 0 2px #99c2ff
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__phoneNumber {
    align-items: center;
    display: flex;
    margin-left: 65px;
    width: 100%
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__countryFlag {
    position: absolute
}

.adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__dropdown__button--active, .adyen-checkout__phone-input .adyen-checkout__input-wrapper .adyen-checkout__dropdown__button--active:hover {
    box-shadow: none
}

.adyen-checkout__iban-input__number {
    padding: 5px 36px 5px 8px;
    text-transform: uppercase
}

.adyen-checkout__threeds2__challenge, .adyen-checkout__threeds2__challenge-container {
    background-color: transparent;
    box-sizing: border-box;
    display: block;
    height: inherit;
    min-height: 400px;
    overflow: hidden;
    position: relative;
    width: 100%
}

.adyen-checkout__threeds2__challenge--01, .adyen-checkout__threeds2__challenge--01 .adyen-checkout__iframe--threeDSIframe {
    height: 400px;
    width: 250px
}

.adyen-checkout__threeds2__challenge--02, .adyen-checkout__threeds2__challenge--02 .adyen-checkout__iframe--threeDSIframe {
    height: 400px;
    width: 390px
}

.adyen-checkout__threeds2__challenge--03, .adyen-checkout__threeds2__challenge--03 .adyen-checkout__iframe--threeDSIframe {
    height: 600px;
    width: 500px
}

.adyen-checkout__threeds2__challenge--04, .adyen-checkout__threeds2__challenge--04 .adyen-checkout__iframe--threeDSIframe {
    height: 400px;
    width: 600px
}

.adyen-checkout__threeds2__challenge--05, .adyen-checkout__threeds2__challenge--05 .adyen-checkout__iframe--threeDSIframe {
    height: 100%;
    width: 100%
}

.adyen-checkout__iframe--threeDSIframe {
    border: 0;
    left: 0;
    position: absolute;
    top: 0
}

.adyen-checkout__qr-loader {
    background: #fff;
    border: 1px solid #d4d9db;
    border-radius: 12px;
    padding: 40px;
    text-align: center
}

.adyen-checkout__qr-loader--result {
    padding: 100px
}

.adyen-checkout__qr-loader--app {
    border: 0;
    border-radius: 0;
    padding: 0
}

.adyen-checkout__qr-loader__brand-logo {
    border-radius: 3px;
    width: 74px
}

.adyen-checkout__qr-loader__subtitle {
    margin: 32px auto 0;
    max-width: 400px
}

.adyen-checkout__qr-loader__subtitle--result {
    margin-bottom: 32px
}

.adyen-checkout__qr-loader__payment_amount, .adyen-checkout__qr-loader__subtitle {
    color: #00112c;
    font-size: 1em;
    line-height: 19px
}

.adyen-checkout__qr-loader__icon {
    height: 88px;
    width: 88px
}

.adyen-checkout__qr-loader__payment_amount {
    font-weight: 700
}

.adyen-checkout__qr-loader__progress {
    background: #d4d9db;
    border-radius: 24px;
    height: 4px;
    margin: 32px auto 12px;
    padding-right: 3%;
    width: 152px
}

[dir=rtl] .adyen-checkout__qr-loader__progress {
    padding-left: 3%;
    padding-right: 0
}

.adyen-checkout__qr-loader__percentage {
    background: #06f;
    border-radius: 24px;
    display: block;
    height: 100%
}

.adyen-checkout__qr-loader__countdown {
    color: #687282;
    font-size: .81em
}

.adyen-checkout__qr-loader > .adyen-checkout__spinner__wrapper {
    margin: 60px 0
}

.adyen-checkout__qr-loader__app-link {
    display: none;
    margin-top: 16px
}

.adyen-checkout__qr-loader__separator__label {
    color: #687282;
    display: block;
    font-size: 13px;
    overflow: hidden;
    position: relative;
    text-align: center;
    z-index: 1
}

.adyen-checkout__qr-loader__separator__label:after, .adyen-checkout__qr-loader__separator__label:before {
    background-color: #e6e9eb;
    content: "\a0";
    height: 1px;
    overflow: hidden;
    position: absolute;
    top: 51%;
    width: 50%
}

.adyen-checkout__qr-loader__separator__label:before {
    margin-left: -52%;
    text-align: right
}

.adyen-checkout__qr-loader__separator__label:after {
    margin-left: 2%
}

.adyen-checkout__button.adyen-checkout__button--qr-loader {
    margin-top: 24px;
    text-decoration: none
}

.adyen-checkout__qr-loader__instructions {
    color: #687282;
    font-size: 1em;
    line-height: 1.5;
    margin-top: 32px
}

.adyen-checkout__qr-loader__actions {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 32px
}

@media only screen and (max-device-width: 1200px) {
    .adyen-checkout__qr-loader__app-link {
        display: block
    }
}

.adyen-checkout__voucher-result--boletobancario .adyen-checkout__voucher-result__code {
    font-size: .81em;
    line-height: 19px;
    padding: 24px;
    word-break: break-all
}

.adyen-checkout__voucher-result--oxxo .adyen-checkout__voucher-result__code {
    font-size: .81em;
    line-height: 19px;
    padding: 24px;
    word-break: break-all
}

.adyen-checkout__alert-message {
    border-radius: 6px;
    display: flex;
    font-size: .81em;
    margin: 0 0 16px;
    padding: 12px;
    text-align: left
}

.adyen-checkout__alert-message--error {
    background: #fbe6ed
}

.adyen-checkout__alert-message--warning {
    background: #ffeacc
}

.adyen-checkout__alert-message--info {
    background: #e5efff
}

.adyen-checkout__alert-message__icon {
    height: 14px;
    margin-right: 8px;
    width: 14px
}

.adyen-checkout__giftcard-result__header {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    font-size: 1em;
    font-weight: 400;
    justify-content: space-between;
    position: relative;
    width: 100%
}

.adyen-checkout__giftcard-result__header__title {
    align-items: center;
    display: flex
}

.adyen-checkout__giftcard-result__name {
    margin-left: 8px
}

.adyen-checkout__giftcard-result__balance {
    list-style: none;
    margin: 16px 0 0;
    padding: 0
}

.adyen-checkout__giftcard-result__balance__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px
}

.adyen-checkout__giftcard-result__balance__item .adyen-checkout__giftcard-result__balance__title--transactionLimit {
    color: #687282
}

.adyen-checkout__giftcard-result__balance__item:last-child {
    margin-bottom: 0
}

.adyen-checkout__giftcard-result__balance__value--amount {
    font-weight: 700
}

.adyen-checkout__giftcard-result__remaining-balance {
    color: #687282;
    font-size: 13px;
    line-height: 19px;
    margin: 8px auto 0;
    text-align: center
}

.DropinComponent-module_adyen-checkout__payment-methods-list__2T9kQ {
    list-style: none;
    margin: 0;
    padding: 0
}

.DropinComponent-module_adyen-checkout__payment-method__2ZClo {
    display: block;
    max-height: 60px
}

.DropinComponent-module_adyen-checkout__payment-method__details__2_jFP {
    display: none
}

.DropinComponent-module_adyen-checkout__payment-method__image__Fg2uw {
    height: 26px;
    width: 40px
}

.DropinComponent-module_adyen-checkout__payment-method__image__wrapper__pTTKr {
    margin-right: 8px
}

[dir=rtl] .DropinComponent-module_adyen-checkout__payment-method__image__wrapper__pTTKr {
    margin-left: 8px;
    margin-right: 0
}

.DropinComponent-module_adyen-checkout__payment-method--selected__1zXEA {
    max-height: 100%
}

.DropinComponent-module_adyen-checkout__payment-method--selected__1zXEA .DropinComponent-module_adyen-checkout__payment-method__details__2_jFP {
    display: block
}

.adyen-checkout__payment-method__disable-confirmation {
    background: #d10244;
    border-left: 1px solid #c70241;
    border-right: 1px solid #c70241;
    color: #fff;
    font-size: .81em;
    margin: 0 -17px;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity .15s ease-out, max-height .15s linear, margin-bottom .1s linear
}

.adyen-checkout__payment-method__disable-confirmation.adyen-checkout__payment-method__disable-confirmation--open {
    margin-bottom: 16px;
    max-height: 62px;
    opacity: 1
}

.adyen-checkout__payment-method__disable-confirmation__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 8px 16px
}

.adyen-checkout__payment-method__disable-confirmation__buttons {
    display: flex
}

.adyen-checkout__payment-method__disable-confirmation__button {
    background: #d10244;
    border: 1px solid transparent;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    display: block;
    height: auto;
    line-height: 14px;
    margin: 0 0 0 8px;
    padding: 8px;
    width: auto
}

.adyen-checkout__payment-method__disable-confirmation__button:hover, .adyen-checkout__payment-method__disable-confirmation__button:hover:focus {
    background: #b8023c;
    box-shadow: none
}

.adyen-checkout__payment-method__disable-confirmation__button:active, .adyen-checkout__payment-method__disable-confirmation__button:hover:active {
    background: #9e0234;
    box-shadow: none
}

.adyen-checkout__payment-method__disable-confirmation__button--remove, .adyen-checkout__payment-method__disable-confirmation__button--remove:disabled {
    border-color: #fff
}

.adyen-checkout__payment-method__disable-confirmation__button--cancel, .adyen-checkout__payment-method__disable-confirmation__button--cancel:disabled {
    border-color: transparent
}

.adyen-checkout__payment-method {
    background: #fff;
    border: 1px solid #e6e9eb;
    cursor: pointer;
    margin-top: -1px;
    position: relative;
    transition: opacity .3s ease-out;
    width: 100%
}

.adyen-checkout__payment-method:focus {
    outline: 0
}

.adyen-checkout__payment-method--selected + .adyen-checkout__payment-method, .adyen-checkout__payment-method:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    margin-top: 0
}

.adyen-checkout__payment-method--next-selected, .adyen-checkout__payment-method:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    margin-bottom: 0
}

.adyen-checkout__payment-method--loading {
    opacity: .2
}

.adyen-checkout__payment-method--selected.adyen-checkout__payment-method--loading {
    opacity: .9
}

.adyen-checkout__payment-method--confirming .adyen-checkout__payment-method__details__content, .adyen-checkout__payment-method--disabling {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.adyen-checkout__payment-method--disabling {
    opacity: .3
}

.adyen-checkout__payment-method__header {
    align-items: center;
    color: #00112c;
    display: flex;
    flex-wrap: nowrap;
    font-size: 1em;
    font-weight: 400;
    justify-content: space-between;
    padding: 12px 16px 12px 44px;
    position: relative;
    transition: background .1s ease-out;
    width: 100%
}

[dir=rtl] .adyen-checkout__payment-method__header {
    padding: 12px 44px 12px 12px
}

.adyen-checkout__payment-method--standalone .adyen-checkout__payment-method__header {
    padding: 16px
}

.adyen-checkout__payment-method__header__title {
    align-items: center;
    background: none;
    border: none;
    color: #00112c;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    font-size: 1em;
    font-weight: 400;
    margin-right: 16px;
    max-width: 100%;
    padding: 4px
}

[dir=rtl] .adyen-checkout__payment-method__header__title {
    margin-left: 16px;
    margin-right: 0
}

.adyen-checkout__payment-method__surcharge {
    color: #687282;
    margin-left: 5px
}

.adyen-checkout__payment-method--selected {
    background: #f7f8f9;
    border: 1px solid #e6e9eb;
    border-radius: 12px;
    cursor: default;
    margin: 8px 0;
    transition: margin .15s cubic-bezier(.4, 0, .2, 1) 0ms, opacity .3s ease-out
}

.adyen-checkout__payment-method--selected .adyen-checkout__payment-method__header {
    flex-wrap: wrap
}

.adyen-checkout__payment-method__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.adyen-checkout__payment-method__name--selected {
    font-weight: 500
}

.adyen-checkout__payment-method__details {
    padding: 0 16px;
    position: relative
}

.adyen-checkout__payment-method__details__content {
    margin: 0 0 16px
}

.adyen-checkout__payment-method__image__wrapper {
    height: 26px;
    position: relative;
    width: 40px
}

.adyen-checkout__payment-method__image__wrapper--outline:after {
    border: 1px solid rgba(0, 27, 43, .17);
    border-radius: 3px;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.adyen-checkout__payment-method__image {
    border-radius: 3px;
    display: block
}

.adyen-checkout__payment-method__brands {
    display: flex;
    flex-basis: auto;
    flex-shrink: 1;
    flex-wrap: wrap;
    height: 16px;
    margin: 4px 0;
    overflow: hidden;
    text-align: right
}

.adyen-checkout__payment-method__brands .adyen-checkout__payment-method__brand-number {
    color: #99A3AD;
    font-size: 13px
}

.adyen-checkout__payment-method--selected .adyen-checkout__payment-method__brands {
    height: auto;
    overflow: visible;
    text-align: left
}

.adyen-checkout__payment-method__brands .adyen-checkout__payment-method__image__wrapper {
    display: inline-block;
    height: 16px;
    margin-right: 4px;
    transition: opacity .2s ease-out;
    width: 24px
}

.adyen-checkout__payment-method__brands .adyen-checkout__payment-method__image__wrapper:last-child {
    margin: 0
}

.adyen-checkout__payment-method--selected .adyen-checkout__payment-method__brands .adyen-checkout__payment-method__image__wrapper {
    margin-bottom: 4px
}

.adyen-checkout__payment-method__brands img {
    height: 16px;
    width: 24px
}

.adyen-checkout__payment-method__image__wrapper--disabled {
    opacity: .25
}

.adyen-checkout__payment-method__radio {
    background-color: #fff;
    border: 1px solid #b9c4c9;
    border-radius: 50%;
    height: 16px;
    left: 16px;
    position: absolute;
    transition: border-color .2s ease-out, box-shadow .2s ease-out;
    width: 16px
}

[dir=rtl] .adyen-checkout__payment-method__radio {
    left: auto;
    right: 16px
}

.adyen-checkout__payment-method--standalone .adyen-checkout__payment-method__radio {
    display: none
}

.adyen-checkout__payment-method__radio:after {
    background-color: #fff;
    border-radius: 50%;
    content: "";
    display: block;
    height: 6px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) scale(0);
    transition: transform .3s ease-out;
    width: 6px
}

.adyen-checkout__payment-method:hover:not(.adyen-checkout__payment-method--selected) .adyen-checkout__payment-method__radio {
    border-color: #99a3ad;
    box-shadow: 0 0 0 2px #d4d9db;
    cursor: pointer
}

.adyen-checkout__payment-method__radio--selected {
    background-color: #06f;
    border: 0;
    transition: all .3s ease-out
}

.adyen-checkout__payment-method__radio--selected:hover {
    box-shadow: 0 0 0 2px rgba(0, 102, 255, .4)
}

.adyen-checkout__payment-method__radio--selected:after {
    transform: translateY(-50%) scale(1)
}

.adyen-checkout__order-payment-methods-list {
    list-style: none;
    margin: 0 auto 16px;
    padding: 0
}

.adyen-checkout__order-payment-method {
    background: #fff;
    border: 1px solid #e6e9eb;
    margin-top: -1px;
    position: relative;
    width: 100%
}

.adyen-checkout__order-payment-method:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px
}

.adyen-checkout__order-payment-method:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px
}

.adyen-checkout__order-payment-method__header {
    align-items: center;
    color: #00112c;
    display: flex;
    flex-wrap: nowrap;
    font-size: 1em;
    font-weight: 500;
    justify-content: space-between;
    padding: 16px;
    position: relative;
    transition: background .1s ease-out;
    width: 100%
}

.adyen-checkout__order-payment-method__header .adyen-checkout__payment-method__header__title {
    padding: 0
}

.input-field {
    color: white !important;
}

.adyen-checkout__order-payment-method__details {
    padding: 0 16px 16px
}

.adyen-checkout__order-payment-method__deducted-amount {
    display: flex;
    font-size: 1em;
    justify-content: space-between;
    line-height: 1em
}

.adyen-checkout__order-payment-method__deducted-amount__label {
    font-size: .81em
}

.adyen-checkout__order-payment-method__deducted-amount__value {
    font-weight: 500
}

.adyen-checkout__order-remaining-amount {
    background: #ffeacc;
    border-radius: 6px;
    color: #7f4a00;
    display: block;
    font-size: .81em;
    margin-bottom: 16px;
    padding: 8px 16px;
    width: 100%
}

.adyen-checkout__order-remaining-amount strong {
    font-weight: 700
}

.adyen-checkout__status {
    align-items: center;
    background-color: #fff;
    border: 1px solid #d4d9db;
    border-radius: 6px;
    color: #00112c;
    display: flex;
    flex-direction: column;
    font-size: 1em;
    height: 350px;
    justify-content: center;
    margin: 0;
    padding: 32px;
    text-align: center
}

.adyen-checkout__status__icon {
    margin-bottom: 24px
}

.adyen-checkout__status .adyen-checkout__spinner__wrapper {
    max-height: 88px
}

.adyen-checkout__dropin, .adyen-checkout__dropin *, .adyen-checkout__dropin :after, .adyen-checkout__dropin :before {
    box-sizing: border-box
}

.adyen-checkout__payment-methods-list--loading {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.adyen-checkout__instant-payment-methods-list {
    list-style: none;
    margin: 0;
    padding: 0
}

.adyen-checkout__instant-payment-methods-list li:not(:last-child) {
    margin-bottom: 8px
}

.adyen-checkout__link {
    color: #06f;
    text-decoration: none
}

.adyen-checkout__link:hover {
    text-decoration: underline
}

.AchInput-module_sf-input__wrapper__1V7mk {
    position: relative
}

.AchInput-module_sf-input__wrapper__1V7mk *, .AchInput-module_sf-input__wrapper__1V7mk :after, .AchInput-module_sf-input__wrapper__1V7mk :before {
    box-sizing: border-box
}

.AchInput-module_adyen-checkout__input__1SeSl {
    display: block;
    max-height: 100px
}

.adyen-checkout__loading-input__form {
    transition: opacity .25s ease-out
}

.adyen-checkout__pm__holderName {
    margin-bottom: 0
}

.adyen-checkout__fieldset__title + .adyen-checkout__ach-sf__form {
    margin-top: 0
}

.adyen-checkout__ach-input .adyen-checkout__fieldset--address, .adyen-checkout__ach-sf__form {
    margin-top: 16px
}

.adyen-checkout__loading-input__form {
    transition: opacity .25s ease-out
}

.adyen-checkout__await {
    background: #fff;
    border: 1px solid #d4d9db;
    border-radius: 12px;
    padding: 40px;
    text-align: center
}

.adyen-checkout__await--result {
    padding: 100px
}

.adyen-checkout__qr-loader--app {
    border: 0;
    border-radius: 0;
    padding: 0
}

.adyen-checkout__await__brand-logo {
    border-radius: 3px;
    width: 74px
}

.adyen-checkout__await__indicator-text, .adyen-checkout__await__subtitle {
    color: #00112c;
    font-size: 1em;
    line-height: 19px;
    margin-top: 32px
}

.adyen-checkout__await__indicator-holder .adyen-checkout__await__indicator-text {
    margin-left: 10px;
    margin-top: 6px
}

.adyen-checkout__await__indicator-holder {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 32px
}

.adyen-checkout__await__subtitle--result {
    margin-bottom: 32px
}

.adyen-checkout__await__icon {
    height: 88px;
    width: 88px
}

.adyen-checkout__await__progress {
    background: #d4d9db;
    border-radius: 24px;
    height: 4px;
    margin: 32px auto 12px;
    width: 152px
}

.adyen-checkout__await__percentage {
    background: #06f;
    border-radius: 24px;
    display: block;
    height: 100%
}

.adyen-checkout__await__countdown {
    color: #687282;
    font-size: .81em
}

.adyen-checkout__await > .adyen-checkout__spinner__wrapper {
    margin: 60px 0
}

.adyen-checkout__await__app-link {
    display: none;
    margin-top: 16px
}

.adyen-checkout__await__separator__label {
    color: #687282;
    display: block;
    font-size: 13px;
    overflow: hidden;
    position: relative;
    text-align: center;
    z-index: 1
}

.adyen-checkout__await__separator__label:after, .adyen-checkout__await__separator__label:before {
    background-color: #e6e9eb;
    content: "\a0";
    height: 1px;
    overflow: hidden;
    position: absolute;
    top: 51%;
    width: 50%
}

.adyen-checkout__await__separator__label:before {
    margin-left: -52%;
    text-align: right
}

.adyen-checkout__await__separator__label:after {
    margin-left: 2%
}

@media only screen and (max-device-width: 1200px) {
    .adyen-checkout__await__app-link {
        display: block
    }
}

.adyen-checkout__blik__helper {
    color: #00112c;
    font-size: 1em;
    font-weight: 400;
    margin: 0 0 16px;
    padding: 0
}

.adyen-checkout__bankTransfer__introduction {
    color: #00112c;
    font-size: .81em;
    font-weight: 400;
    margin: 0 0 16px;
    padding: 0
}

.adyen-checkout__bankTransfer__emailField {
    margin: 0 0 16px
}

.adyen-checkout__bacs--confirm {
    position: relative
}

.adyen-checkout__bacs--confirm .adyen-checkout-input__inline-validation--valid {
    display: none
}

.adyen-checkout__bacs .adyen-checkout__field--inactive {
    pointer-events: none
}

.adyen-checkout__bacs .adyen-checkout__bacs--edit {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -25px;
    width: 20%
}

.adyen-checkout__bacs .adyen-checkout__bacs--edit-dropin {
    top: -50px
}

.adyen-checkout__bacs .adyen-checkout__bacs--edit .adyen-checkout__bacs--edit-button {
    background: none;
    border: none;
    color: #06f;
    cursor: pointer;
    text-align: end;
    text-decoration: underline
}

.adyen-checkout__voucher-result__introduction {
    font-size: 1em;
    max-width: 420px
}

.adyen-checkout__klarna-widget {
    pointer-events: all
}

/*# sourceMappingURL=adyen.css.map */
