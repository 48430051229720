@mixin override-mat-tab-link {
    .booking-list {
        .mat-tab-header {
            margin-bottom: 48px;
            border-bottom: none;

            .mat-tab-links {
                border-bottom: 0.5px solid rgba(255, 255, 255, .5);
                width: max-content;
                @apply justify-between w-full md:justify-start md:w-max
            }

            .mat-tab-link {
                font-size: 20px;
                font-weight: 500;

                &.mat-tab-label-active {
                    color: #e345ff;
                    font-weight: bold;
                    opacity: 100%;
                }
            }
        }
    }
}

@mixin override-mat-spinner-color {
    .uploader-status .mat-progress-spinner circle, .mat-spinner circle {
        stroke: #FFFFFF;
    }
}

@mixin override-mat-menu-color {
    .user-menu .mat-menu-content {
        background-color: $purple-600 !important;
    }
}

@mixin override-mat-step-text-label {
    .mat-step-text-label{
        font-size: 16px;
    }
}

@mixin override-mat-box {
    .mat-checkbox-layout {
        align-items: center !important;
    }
}
