//Typography

.font-xxs {
    font-size: $font-xxs;
}

.font-xs {
    font-size: $font-xs;
}

.font-sm {
    font-size: $font-sm;
}

.font-18 {
    font-size: $font-18;
}

.font-md {
    font-size: $font-md;
}

.font-lg {
    font-size: $font-lg;
}

.font-xl {
    font-size: $font-xl;
}

.font-xxl {
    font-size: $font-xxl;
}

.font-xxxl {
    font-size: $font-xxxl;
}

//Colouring

.txt-gray-200 {color: $gray-200}
.txt-gray-300 {color: $gray-300}
.txt-gray-400 {color: $gray-400}
.txt-gray-500 {color: $gray-500}
.txt-gray-600 {color: $gray-600}
.txt-purple-10 {color: $purple-10}
.txt-purple-050 {color: $purple-050}
.txt-purple-060 {color: $purple-060}
.txt-purple-100 {color: $purple-100}
.txt-purple-300 {color: $purple-300}
.txt-purple-500 {color: $purple-500}
.txt-purple-600 {color: $purple-600}
.txt-purple-700 {color: $purple-700}
.txt-purple-800 {color: $purple-800}
.txt-green-100 {color: $green-100}
.txt-red-500 {color: $red-500 !important;}
.bg-color-purple-600 {background-color: $purple-600 !important}
.bg-color-purple-700 {background-color: $purple-700 !important}
.bg-color-purple-900 {background-color: $purple-900 !important}
.bg-color-purple-1000 {background-color: $purple-1000 !important}
.bg-color-purple-300 {background-color: $purple-300 !important}
.bg-color-purple-100 {background-color: $purple-100 !important}
.bg-color-red-500 {background-color: $red-500 !important}
.bg-color-teal-100 {background-color: $teal-100 !important}


.border-color-red-500 {border-color: $red-500 !important}
.border-color-gray-600 {border-color: $gray-600 !important}
.border-color-purple-300 {border-color: $purple-300 !important}
.border-color-teal-500 {border-color: $teal-500 !important}

.disabled-link {color: #8e25a1}

.backdrop {
    background-color: $black;
    opacity: 0.8 !important;
}

.width-95 {
    width: 95%;
}
