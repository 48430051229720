$white: #fff;
$black: #000;

$gray-200: #828282;
$gray-300: #808080;
$gray-400: #c4c4c4;
$gray-500: #aaa;
$gray-600: #505050;

$teal-500: #32afb8;
$teal-100: rgba(50, 175, 184, 0.08);

$purple-10: #bfb5ce;
$purple-050: #7D738C;
$purple-060: #524467;
$purple-100: #e345ff;
$purple-300: #b637cc;
$purple-400: #911ca2;
$purple-500: #4a316d;
$purple-600: #382255;
$purple-700: #301a4e;
$purple-800: #26153f;
$purple-900: #2E1A47;
$purple-1000: #1b0d2e;

$red-500: #e53935;

$green-100: #37c2cc;
