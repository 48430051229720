/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import 'media-query';
@import "variables/main";
@import "material-override";
@import "adyen-styles";
@import "~styles/variables/main";

html,
body {
    height: 100%;
}

.myPanelClass {
    margin-top: 25px !important;
    border-radius: 4px;
    box-shadow: 0 16px 40px 0 rgba(0, 0, 0, 0.5);
    background-color: #2d1e41;
    color: white;
    margin-left: 8px;
    min-width: calc(100% + 16px) !important;
}

.notificationMenu + * .cdk-overlay-pane {
    background-color: #2d1e41;
    color: white;
    max-height: 408px;
    border-radius: 8px;
    box-shadow: 0 16px 40px 0 rgba(0, 0, 0, 0.6);
    border: solid 1px #46375b;
}

.notificationMenu + * .mat-menu-panel {
    max-width: 400px;
}

.notification-menu-item.mat-menu-item {
    line-height: 18px;
    height: auto !important;
    padding: 12px 24px;
    text-align: left;
    white-space: normal;
}

body {
    margin: 0;
    font-family: $Fieldwork !important;
    background: $purple-800 !important;
}


.owl-theme {
    .owl-nav {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
    }

    .owl-nav .owl-dot,
    .owl-nav .owl-dots {
        padding: 4px 0px !important;

    }

    .owl-nav [class*=owl-],
    .owl-nav [class*=owl-]:hover {
        background: transparent;
    }
}


search ::placeholder {
    color: $white !important;
}


/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #FFF;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 4px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #aaa;
    border-radius: 4px;
}

.mat-drawer-backdrop {
    opacity: 0.8 !important;
    background-color: #000 !important;
}

.text-tealish {
    color: #32afb8
}

.footer-color {
    background-color: #1c0f30 !important;
}

//To be removed when we fix tailwind arbitrary values
.border-pink {
    border-color: $purple-100 !important;
}

.text-stroke {
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
}

// include material overrides
@include override-mat-tab-link();
@include override-mat-spinner-color();
@include override-mat-menu-color();
@include override-mat-box();

.booking-container {
    @include override-mat-step-text-label();
}

.cdk-global-scrollblock {
    position: initial;
}

.ico-mail {
    width: 24px;
    height: 24px;
    background: url("#{$path-to-icons}/emails-gray.svg") no-repeat center;
}

.ico-lock {
    width: 24px;
    height: 24px;
    background: url("#{$path-to-icons}/lock.svg") no-repeat center;
}

.transparent-field {
    .mat-form-field-wrapper {
        .mat-form-field-flex {
            background: transparent !important;
            border-radius: 8px;
            border: solid 1px $gray-600;
        }
    }
}


.explore {
    .mat-menu-content {
        background-color: $purple-800 !important;
    }

    button.mat-menu-item {
        &:hover {
            background-color: #49306c;
        }
    }
}

::-webkit-scrollbar-track {
    background: transparent !important;
}

.iub_container {
    color: white !important;

    h1, h2, h3, a {
        color: $purple-100 !important;
    }

    p {
        color: white !important;
    }

    img {
        width: 200px !important;
    }
}

.cont {
    ul {
        list-style: disc !important;
    }

    ol {
        list-style: auto !important;
    }
}

.custom-container {
    width: 100%;
}

@media (min-width: 640px) {
    .custom-container {
        max-width: 640px;
    }
}

@media (min-width: 768px) {
    .custom-container {
        max-width: 768px;
    }
}

@media (min-width: 1024px) {
    .custom-container {
        max-width: 1024px;
    }
}

@media (min-width: 1280px) {
    .custom-container {
        max-width: 1280px;
    }
}

@media (min-width: 1360px) {
    .custom-container {
        max-width: 1360px;
    }
}

@media (min-width: 1536px) {
    .custom-container {
        max-width: 1536px;
    }
}

@media (min-width: 1700px) {
    .custom-container {
        max-width: 1700px;
    }
}
