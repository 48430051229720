@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/raleway/Raleway-Regular.ttf') format('truetype')
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    src: url('../../assets/fonts/raleway/Raleway-Medium.ttf') format('truetype')
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: bold;
    src: url('../../assets/fonts/raleway/Raleway-Bold.ttf') format('truetype')
}

@font-face {
    font-family: 'Fieldwork';
    src: url('../../assets/fonts/fieldwork/fieldwork-humregular-webfont.woff2') format('woff2'),
    url('../../assets/fonts/fieldwork/fieldwork-humregular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Fieldwork';
    src: url('../../assets/fonts/fieldwork/fieldwork-humbold-webfont.woff2') format('woff2'),
    url('../../assets/fonts/fieldwork/fieldwork-humbold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}

$Raleway: Raleway, sans-serif;
$Fieldwork: Fieldwork, sans-serif;

$font-xxs: 12px;
$font-xs: 14px;
$font-sm: 16px;
$font-18: 18px;
$font-md: 24px;
$font-lg: 32px;
$font-xl: 48px;
$font-xxl: 56px;
$font-xxxl: 250px;
